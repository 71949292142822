<template>
  <div v-if="isLoading">
    <span class="saving"></span>
  </div>

  <div
    v-else
    id="graph"
    v-bind:class="{
      'test-mode': !$store.state.workflowTesting.isInTestMode,
      published: isPublishedMode()
    }"
  >
    <div id="sub">
      <div>
        <span><strong>Workflow</strong>: {{ workflowName }}</span>
        <span><strong>Version</strong>: {{ workflowVersion }}</span>
      </div>

      <slider-toggle
        :options="Object.keys(modes)"
        v-on:selected="switchMode"
      ></slider-toggle>

      <nav>
        <ul>
          <li id="btn-save" v-if="graphLoaded && isDraftMode()">
            <SaveButton></SaveButton>
          </li>
          <li id="btn-copy" v-if="graphLoaded">
            <a href="#" @click="doCopy" title="Copy workflow data to clipboard."
              >Copy</a
            >
          </li>
          <li id="btn-log" v-if="workflowID">
            <a href="#" @click="log" title="View logs for this workflow."
              >Log</a
            >
          </li>
          <li
            id="btn-publish-version"
            v-if="
              graphLoaded &&
                isDraftMode() &&
                !$store.state.workflowTesting.isInTestMode
            "
          >
            <a
              href="#"
              @click="publishVersion"
              title="Publish a new version for this workflow."
              v-bind:class="{ busy: isPublishingVersion }"
              >Publish version</a
            >
          </li>
        </ul>
      </nav>

      <div id="test-panel">
        <div id="test-mode-toggle">
          Test mode
          <input
            type="checkbox"
            title="Turn on Test Mode."
            v-model="$store.state.workflowTesting.isInTestMode"
          />
        </div>

        <div
          id="test-controls"
          v-show="$store.state.workflowTesting.isInTestMode"
        >
          <div id="test-list-dropdown-container">
            <div v-if="showTestSelectDropdown">
              <select v-model="selectedTest">
                <option
                  v-for="(test, i) in $store.state.workflowTesting.testList"
                  :value="test.id"
                  :key="test.id"
                >
                  {{ i + 1 }}. {{ test.name }}
                </option>
              </select>
            </div>
            <div v-if="isAddingTest || isDuplicatingTest">
              <input
                ref="add-test-input"
                id="add-test-input"
                type="text"
                placeholder="Test name"
                v-on:keyup="addTestKeyHandler"
              />
            </div>
          </div>

          <div id="test-management-container">
            <!-- (Save/Confirm) / Cancel buttons -->
            <div v-if="isAddingTest || isDuplicatingTest || isDeletingTest">
              <button
                title="Confirm"
                class="test-button"
                id="confirm-test-button"
                @click="handleClickManagementButtonConfirmation"
                v-bind:class="{ new: isAddingTest }"
              ></button>
              <button
                title="Cancel"
                class="test-button"
                id="cancel-test-button"
                @click="handleClickManagementButtonCancel"
              ></button>
            </div>
            <!-- Add/duplicate/delete buttons -->
            <div v-else>
              <button
                title="Add new test"
                ref="add-test-button"
                id="add-test-button"
                class="test-button"
                @click="handleClickAddTest"
              ></button>
              <button
                title="Duplicate selected test"
                ref="duplicate-test-button"
                id="duplicate-test-button"
                class="test-button"
                @click="handleClickDuplicateTest"
                v-bind:disabled="selectedTest === 0"
              ></button>
              <button
                title="Delete selected test"
                ref="delete-test-button"
                id="delete-test-button"
                class="test-button"
                @click="handleClickDeleteButton"
                v-bind:disabled="selectedTest === 0"
              ></button>
            </div>
          </div>

          <div id="test-runner-container">
            <button
              v-show="showPlayTestButton"
              @click="!isRunningTest ? handleClickRunTest() : () => {}"
              title="Run test"
              id="run-test"
              v-bind:class="[
                latestTestResult !== null &&
                  (latestTestResult[0].Passed ? 'success' : 'failure')
              ]"
            >
              <span
                id="arrow"
                v-bind:class="[
                  isRunningTest ? 'test-button-running' : 'test-button-ready'
                ]"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <baklava-editor :plugin="viewPlugin"></baklava-editor>
    <div id="unsaved" v-show="needsSaving">You have unsaved changes</div>
  </div>
</template>

<script>
import { ViewPlugin } from "@baklavajs/plugin-renderer-vue";
import { OptionPlugin } from "@baklavajs/plugin-options-vue";
import { Engine } from "@baklavajs/plugin-engine";
import { createSimpleSnappingProvider } from "@baklavajs/plugin-renderer-vue";

// Options
import SidebarOption from "../components/SidebarOption.vue";
import KeyValueOption from "../components/KeyValueOption.vue";
import JsonBuilderOption from "../components/JsonBuilderOption.vue";
import QueryOption from "../components/QueryOption.vue";
import TestFixtureInput from "../components/TestFixtureInput.vue";
import RegexOption from "../components/RegexOption.vue";
import AddInputOption from "../components/AddInputOption.vue";
import TextConditionalOption from "../components/TextConditionalOption.vue";
import ErrorMappingOption from "../components/ErrorMappingOption.vue";
import NumberConditionalOption from "../components/NumberConditionalOption.vue";
import NumberOption from "../components/NumberOption.vue";

// Nodes
import { Coalesce } from "@/nodes/coalesce";
import { Configuration } from "@/nodes/configuration";
import { Date as DateNode } from "@/nodes/date";
import { DateFormat } from "@/nodes/dateFormat";
import { DateTransform } from "@/nodes/dateTransform";
import { DateOperation } from "@/nodes/dateOperation";
import { EmitBaselinesDiscovered } from "@/nodes/emitBaselinesDiscovered";
import { EmitOrderFailed } from "@/nodes/emitOrderFailed";
import { EmitOrderSucceeded } from "@/nodes/emitOrderSucceeded";
import { ErrorMapping } from "@/nodes/errorMapping";
import { Exists } from "@/nodes/exists";
import { Fork } from "@/nodes/fork";
import { HTTP } from "@/nodes/http";
import { JsonBuilder } from "@/nodes/jsonBuilder";
import { Log } from "@/nodes/log";
import { Loop } from "@/nodes/loop";
import { LoopContinue } from "@/nodes/loopContinue";
import { LoopEnd } from "@/nodes/loopEnd";
import { Math } from "@/nodes/math";
import { MenuOutdated } from "@/nodes/menuOutdated";
import { Number } from "@/nodes/number";
import { NumberConditional } from "@/nodes/numberConditional";
import { NumberOperation } from "@/nodes/numberOperation";
import { OrderCreated } from "@/nodes/orderCreated";
import { Pick } from "@/nodes/pick";
import { Plu } from "@/nodes/plu";
import { Regex } from "@/nodes/regex";
import { SetValue } from "@/nodes/setValue";
import { StoreBaseline } from "@/nodes/storeBaseline";
import { Text } from "@/nodes/text";
import { TextConditional } from "@/nodes/textConditional";
import { TextJoin } from "@/nodes/textJoin";
import { TextLiteral } from "@/nodes/textLiteral";
import { TextOperation } from "@/nodes/textOperation";
import { VariableGet } from "@/nodes/variableGet";
import { VariableSet } from "@/nodes/variableSet";
import axios from "axios";
import { isEqual, map, pick, sortBy } from "lodash";
import { MapPick } from "@/nodes/mapPick";
import { MapSet } from "@/nodes/mapSet";
import { Map } from "@/nodes/map";
import { MapValues } from "@/nodes/mapValues";
import { List } from "@/nodes/list";
import { ListOperation } from "@/nodes/listOperation";
import { Assert } from "@/nodes/assert";

import SaveButton from "../components/SaveButton";
import SliderToggle from "@/components/SliderToggle";

const MODES = {
  Published: 0,
  Draft: 1
};

const STOP_NODE_DRAG = Symbol("stopNodeDrag");

export default {
  components: { SliderToggle, SaveButton },
  data() {
    return {
      viewPlugin: new ViewPlugin(),
      engine: new Engine(false),
      workflow: {},
      tests: [],
      isAddingTest: false,
      isDuplicatingTest: false,
      isDeletingTest: false,
      workflowID: null,
      workflowName: null,
      workflowVersion: 1,
      modes: MODES,
      mode: MODES.Published,
      isPublishingVersion: false
    };
  },
  created() {
    const editor = this.$store.state.editor;

    // Register the plugins
    // The view plugin is used for rendering the nodes
    editor.use(this.viewPlugin);
    // The option plugin provides some default option UI elements
    editor.use(new OptionPlugin());
    // The engine plugin calculates the nodes in the graph in the
    // correct order using the "calculate" methods of the nodes
    editor.use(this.engine);
    // Show a minimap in the top right corner
    this.viewPlugin.enableMinimap = false;

    this.viewPlugin.snappingProvider = createSimpleSnappingProvider(10, 10);

    this.viewPlugin.registerOption("SidebarOption", SidebarOption);
    this.viewPlugin.registerOption("KeyValueOption", KeyValueOption);
    this.viewPlugin.registerOption("JsonBuilderOption", JsonBuilderOption);
    this.viewPlugin.registerOption("QueryOption", QueryOption);
    this.viewPlugin.registerOption("TestFixtureInput", TestFixtureInput);
    this.viewPlugin.registerOption("RegexOption", RegexOption);
    this.viewPlugin.registerOption("AddInputOption", AddInputOption);
    this.viewPlugin.registerOption(
      "TextConditionalOption",
      TextConditionalOption
    );
    this.viewPlugin.registerOption(
      "NumberConditionalOption",
      NumberConditionalOption
    );
    this.viewPlugin.registerOption("NumberOption", NumberOption);
    this.viewPlugin.registerOption("ErrorMappingOption", ErrorMappingOption);

    // register your nodes, node options, node interface types, ...
    editor.registerNodeType("Assert", Assert, "Testing");
    editor.registerNodeType("Loop", Loop, "Control Flow");
    editor.registerNodeType("LoopContinue", LoopContinue, "Control Flow");
    editor.registerNodeType("LoopEnd", LoopEnd, "Control Flow");
    editor.registerNodeType("Regex", Regex);
    editor.registerNodeType("Math", Math, "Number");
    editor.registerNodeType("NumberOperation", NumberOperation, "Number");
    editor.registerNodeType("Log", Log);
    editor.registerNodeType("HTTP", HTTP);
    editor.registerNodeType("TextConditional", TextConditional, "Text");
    editor.registerNodeType("ErrorMapping", ErrorMapping);
    editor.registerNodeType("NumberConditional", NumberConditional, "Number");
    editor.registerNodeType("JsonBuilder", JsonBuilder);
    editor.registerNodeType("DateFormat", DateFormat, "Date");
    editor.registerNodeType("DateTransform", DateTransform, "Date");
    editor.registerNodeType("DateOperation", DateOperation, "Date");
    editor.registerNodeType("Date", DateNode, "Date");
    // editor.registerNodeType("OrderRejected", OrderRejected);
    editor.registerNodeType("Text", Text, "Text");
    editor.registerNodeType("TextJoin", TextJoin, "Text");
    editor.registerNodeType("TextLiteral", TextLiteral, "Text");
    editor.registerNodeType("TextOperation", TextOperation, "Text");
    editor.registerNodeType("Pick", Pick);
    editor.registerNodeType("Plu", Plu);
    editor.registerNodeType("Fork", Fork, "Control Flow");
    editor.registerNodeType("Coalesce", Coalesce, "Control Flow");
    editor.registerNodeType(
      "EmitBaselinesDiscovered",
      EmitBaselinesDiscovered,
      "Events"
    );
    editor.registerNodeType("Exists", Exists);
    editor.registerNodeType("StoreBaseline", StoreBaseline);
    editor.registerNodeType("SetValue", SetValue);
    editor.registerNodeType("Number", Number, "Number");
    editor.registerNodeType("Configuration", Configuration);
    editor.registerNodeType("VariableGet", VariableGet, "Variables");
    editor.registerNodeType("VariableSet", VariableSet, "Variables");
    editor.registerNodeType("Map", Map, "Maps");
    editor.registerNodeType("MapValues", MapValues, "Maps");
    editor.registerNodeType("MapPick", MapPick, "Maps");
    editor.registerNodeType("MapSet", MapSet, "Maps");
    editor.registerNodeType("EmitOrderFailed", EmitOrderFailed, "Events");
    editor.registerNodeType("EmitOrderSucceeded", EmitOrderSucceeded, "Events");
    editor.registerNodeType("List", List, "Variables");
    editor.registerNodeType("ListOperation", ListOperation, "Variables");

    // DO NOT register any nodes below this point, only register above
    editor.registerNodeType("OrderCreated", OrderCreated, "Events");
    editor.registerNodeType("MenuOutdated", MenuOutdated, "Events");

    // Register handlers for the "unsaved changes" feature.
    editor.hooks.load.tap(this, this.registerWorkflowChangedHandlers);

    // Unsaved changes feature
    this.$on("workflow_changed", () => {
      const workflowChanged = editor.save();

      const nodesEqual = isEqual(
        this.normalisedNodes(this.$store.state.workflow.data.graph.nodes),
        this.normalisedNodes(workflowChanged.nodes)
      );

      const connectionsEqual = isEqual(
        this.normalisedConnections(
          this.$store.state.workflow.data.graph.connections
        ),
        this.normalisedConnections(workflowChanged.connections)
      );

      this.$store.state.needsSaving = !nodesEqual || !connectionsEqual;
    });
  },
  mounted() {
    if (this.$route.name === "ViewWorkflow") {
      this.load();
    }

    if (this.$route.params.workflow) {
      this.workflowID = this.$route.params.workflow;
    }
  },
  computed: {
    needsSaving: function() {
      return this.$store.state.needsSaving;
    },
    isLoading: function() {
      return this.$store.state.graph.isLoading;
    },
    isRunningTest: function() {
      return this.$store.state.workflowTesting.runner.isLoading;
    },
    latestTestResult: function() {
      return this.$store.state.workflowTesting.runner.latestResult;
    },
    // Used to determine whether to show test select dropdown, versus an input
    showTestSelectDropdown: function() {
      return (
        this.$store.state.workflowTesting.testList.length > 0 &&
        !this.isAddingTest &&
        !this.isDuplicatingTest
      );
    },
    showPlayTestButton: function() {
      return (
        this.$store.state.workflowTesting.testList.length > 0 &&
        !this.isAddingTest &&
        !this.isDuplicatingTest &&
        !this.isDeletingTest
      );
    },
    selectedTest: {
      get() {
        return this.$store.state.workflowTesting.selectedTest.id;
      },
      set(testId) {
        this.fetchTest(testId);
        this.$store.state.workflowTesting.selectedTest.id = testId;
      }
    },
    graphLoaded: function() {
      // Check that a workflow has been selected and fully loaded
      return this.workflowID !== null && !this.$store.state.graph.isLoading;
    }
  },
  methods: {
    load: async function() {
      this.$store.state.graph.isLoading = true;

      // Fetch the workflows baklava graph
      let fetchGraph = axios.get(this.$route.path).then(async response => {
        // Store the workflow
        this.$store.state.workflow.data = response.data;
        try {
          this.$store.state.editor.load(response.data.graph);
          this.workflowName = response.data.name;
        } catch (e) {
          // Old graphs with old nodes are trying to be loaded on a newer version of the front-end, so we have mis-matches between node types.
          // Need to find a more permanent fix for this, however baklava "gracefully" handles this by loading the graph anyway (albeit with this error thrown).
          console.log(
            `TODO - FIX THIS ERROR BAKLAVA ALWAYS THROWS EVEN ON SUCCESSFUL LOAD - ${e}`
          );
        }
      });

      // Fetch a full list of tests
      let fetchWorkflowTests = axios
        .get(`${this.$route.path}/tests`)
        .then(async response => {
          // Store the workflow tests, if there are any
          this.$store.state.workflowTesting.testList = response.data;
        });

      await Promise.allSettled([fetchGraph, fetchWorkflowTests]).then(
        values => {
          if (
            values.every(
              v =>
                v.status === "fulfilled" ||
                // Will recieve a 404 if no tests added yet, but we should still remove loader
                (v.status === "rejected" && v.reason.response.status === 404)
            )
          ) {
            this.$store.state.graph.isLoading = false;
          } else {
            // Log errors for troublesome requests
            values.forEach(v => {
              if (v.status === "rejected") {
                console.log(v.reason);
              }
            });
          }
        }
      );

      // Once we know all the ID's for our tests, select the first one and fetch all data for it
      let testIdToSelect =
        this.$store.state.workflowTesting.testList[0]?.id || 0;

      if (testIdToSelect) {
        this.fetchTest(testIdToSelect);
      }
    },
    fetchTest: function(testId) {
      // Display loader while fetching test info
      this.$store.state.graph.isLoading = true;
      axios
        .get(`${this.$route.path}/tests/${testId}`)
        .then(async response => {
          this.$store.state.workflowTesting.selectedTest = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.$store.state.graph.isLoading = false;
        });
    },
    addTestKeyHandler: function(e) {
      // Submit
      if (e.key === "Enter") {
        this.saveTest();
      }

      // Cancel
      if (e.key === "Escape") {
        this.$refs["add-test-input"].value = "";
      }
    },
    focusTestnameInput() {
      this.$nextTick(() => {
        const addTestInput = this.$refs["add-test-input"];
        addTestInput.focus();
        addTestInput.addEventListener("keyup", this.addTestKeyHandler);
      });
    },
    handleClickAddTest() {
      this.isAddingTest = true;
      this.focusTestnameInput();
    },
    handleClickDuplicateTest() {
      // When this is true, we'll send the fixture data when we save the test
      this.isDuplicatingTest = true;
      this.focusTestnameInput();
    },
    handleClickDeleteButton() {
      this.isDeletingTest = true;
    },
    handleClickManagementButtonCancel() {
      this.isAddingTest = false;
      this.isDuplicatingTest = false;
      this.isDeletingTest = false;
    },
    handleClickManagementButtonConfirmation() {
      return this.isDeletingTest ? this.deleteTest() : this.saveTest();
    },
    handleClickRunTest() {
      this.$store.state.workflowTesting.runner.isLoading = true;
      // call GET /projects/{project}/workflows/{workflow}/tests/{test}/run and check for 200 response
      axios
        .post(
          `/projects/${this.$route.params.project}/workflows/${this.$route.params.workflow}/run-tests`,
          {
            tests: [this.$store.state.workflowTesting.selectedTest.id]
          }
        )
        .then(response => {
          if (response.status === 200) {
            // TODO - in the future we'll be doing something more fancy
            // with the testRunResults, whereby we'll have each assert node show result
            this.$store.state.workflowTesting.runner.latestResult =
              response.data;
          }
        })
        .catch(error => {
          // TODO - Add toast message or similar to display, for now just log
          console.log(error);
        })
        .finally(() => {
          this.$store.state.workflowTesting.runner.isLoading = false;
        });
    },
    saveTest() {
      this.isAddingTest = false;

      // Validate test name
      const addTestInput = this.$refs["add-test-input"];
      if (addTestInput.value === "") {
        return false;
      }

      const test = {
        name: addTestInput.value,
        nodes: this.isDuplicatingTest
          ? this.$store.state.workflowTesting.selectedTest.nodes
          : []
      };

      this.isDuplicatingTest = false;

      axios
        .post(
          `/projects/${this.$route.params.project}/workflows/${this.$route.params.workflow}/tests`,
          test
        )
        .then(async res => {
          // Add test to store, and also create the test on the backend with just a name initially
          this.$store.state.workflowTesting.testList.push({
            ...test,
            id: res.data.id
          });
          // Reset form
          addTestInput.value = "";
          // Automatically select the new test in the select drop-down
          this.$store.state.workflowTesting.selectedTest = {
            ...test,
            id: res.data.id
          };
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteTest() {
      this.isDeletingTest = false;

      axios
        .delete(
          `${this.$route.path}/tests/${this.$store.state.workflowTesting.selectedTest.id}`
        )
        .then(async () => {
          // Remove test from store
          this.$store.state.workflowTesting.testList = this.$store.state.workflowTesting.testList.filter(
            test =>
              test.id !== this.$store.state.workflowTesting.selectedTest.id
          );

          // Select first test (if there's still tests available), and fetch it.
          let testIdToSelect =
            this.$store.state.workflowTesting.testList[0]?.id || 0;

          // Update the state to reflect the newly selected test, even if it's 0 (no test)
          this.$store.state.workflowTesting.selectedTest.id = testIdToSelect;

          if (testIdToSelect) {
            this.fetchTest(testIdToSelect);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    registerWorkflowChangedHandlers: function() {
      const events = this.$store.state.editor.events;

      events.addNode.addListener(this, () => {
        this.$emit("workflow_changed");
      });

      events.removeNode.addListener(this, () => {
        this.$emit("workflow_changed");
      });

      events.addConnection.addListener(this, () => {
        this.$emit("workflow_changed");
      });

      events.removeConnection.addListener(this, () => {
        this.$emit("workflow_changed");
      });

      this.viewPlugin.hooks.renderNode.tap(this, () => {
        this.$emit("workflow_changed");
      });

      this.viewPlugin.hooks.renderOption.tap(this, () => {
        this.$emit("workflow_changed");
      });
    },
    normalisedNode: function(node) {
      return pick(node, ["name", "options", "position", "type"]);
    },
    normalisedNodes: function(nodes) {
      return sortBy(map(nodes, this.normalisedNode), ["name"]);
    },
    normalisedConnection: function(connection) {
      return pick(connection, ["from", "to"]);
    },
    normalisedConnections: function(connections) {
      return sortBy(map(connections, this.normalisedConnection), [
        "from",
        "to"
      ]);
    },
    log: function() {
      let url =
        "https://kibana-production.flyt-tools.com/_dashboards/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:daa651d0-f6d6-11ec-bbf2-832f76fcafca,key:app,negate:!f,params:(query:runway-backend),type:phrase),query:(match_phrase:(app:runway-backend))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:daa651d0-f6d6-11ec-bbf2-832f76fcafca,key:job_id,negate:!f,params:(query:'" +
        this.workflowID +
        "'),type:phrase),query:(match_phrase:(job_id:'" +
        this.workflowID +
        "')))),index:daa651d0-f6d6-11ec-bbf2-832f76fcafca,interval:m,query:(language:lucene,query:''),sort:!('@timestamp',desc))";

      window.open(url, "_blank");
    },
    doCopy: function() {
      this.$copyText(JSON.stringify(this.$store.state.editor.save()));
    },
    switchMode: function(mode) {
      // Only process legitimate mode changes
      if (mode === this.mode) return;

      this.mode = mode;

      if (this.isPublishedMode()) {
        // Stop nodes from being movable
        this.viewPlugin.events.beforeNodeMove.addListener(
          STOP_NODE_DRAG,
          () => false
        );
      } else {
        // Allow nodes to be movable
        this.viewPlugin.events.beforeNodeMove.removeListener(STOP_NODE_DRAG);
      }
    },
    publishVersion: function() {
      if (this.isPublishingVersion) return;

      this.isPublishingVersion = true;

      axios
        .post(
          `/projects/${this.$route.params.project}/workflows/${this.$route.params.workflow}/version`
        )
        .then(async response => {
          // TODO: Update the workflow version in the sub navigation when the API response is updated to return the
          // newly created version number
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isPublishingVersion = false;
        });
    },
    isPublishedMode: function() {
      return this.mode === MODES.Published;
    },
    isDraftMode: function() {
      return this.mode === MODES.Draft;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/main.scss";

#graph {
  // Exclude the header / navigation (50px) and the sub navigation (50px)
  height: calc(100vh - 100px);
}

.saving {
  top: calc(50vh - 25px);
  left: calc(50vw - 25px);
  position: absolute;
}

.saving:before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  border: 5px white dotted;
  border-left-color: transparent;
  border-radius: 100%;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.node-editor {
  .background {
    background-color: transparent !important;
  }
}

#sub {
  display: flex;
  height: 50px;
  padding: 0 80px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.88rem;
  color: #ffffff;
  line-height: 50px;
  background: #262626;

  > div:first-child {
    margin: 0 1.5rem 0 0;

    span {
      margin: 0 1rem 0 0;
    }
  }

  nav {
    flex-grow: 1;

    ul {
      list-style: none;
      height: 100%;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        height: 100%;
        margin: 0 2rem 0 0;

        a {
          display: block;
          height: 100%;
          padding: 0 0 0 1.5rem;
          color: #fff;
          text-decoration: none;

          &:hover,
          &:active {
            color: $orange !important;
          }
        }

        &#btn-save {
          div {
            height: 100%;
          }

          background: url("../assets/images/save.svg") left center no-repeat;
        }

        &#btn-copy {
          background: url("../assets/images/copy.svg") left center no-repeat;
        }

        &#btn-log {
          background: url("../assets/images/log.svg") left center no-repeat;
        }

        &#btn-publish-version {
          a {
            height: 2rem;
            padding: 0 0.5rem;
            font-weight: 700;
            color: #000000;
            line-height: 2rem;
            background: $color-orange;
            border-radius: 100px;

            &:hover {
              color: #ffffff !important;
            }

            &.busy {
              opacity: 0.35;
              cursor: wait;
            }
          }
        }
      }
    }
  }
}

#test-panel {
  display: flex;

  #test-mode-toggle {
    order: 1;

    input {
      cursor: pointer;
    }
  }

  #test-controls {
    order: 0;
    display: flex;
    column-gap: 0.5rem;

    #test-list-dropdown-container {
      select {
        min-width: 9.5rem;
        box-sizing: content-box;
        padding: 0.4rem 0.8rem;
        color: #fff;
        border-radius: $border-radius-medium $border-radius-medium;
        border: solid 1px $grey;
        background: $off-black;
        cursor: pointer;

        &:hover {
          border-color: $orange;
        }
      }

      input#add-test-input {
        width: 9.5rem;
        padding: 0.4rem 0.8rem;
        color: #fff;
        border-radius: $border-radius-medium $border-radius-medium;
        border: solid 1px $grey;
        background: $off-black;

        &:hover {
          color: #fff !important;
          border: solid 1px $grey;
        }
      }
    }

    #test-management-container {
      min-width: 105px;
      display: flex;

      // First level div, whereby we decide to render "confirm/cancel", or "add/dupe/delete"
      > div {
        display: flex;
        flex: 1;

        // Make sure the buttons take up the full "#test-management-container" width
        button {
          width: 25px;
          height: 25px;
          margin: 0.7rem 0.6rem 0 0;
          cursor: pointer;
          border: none;
          background: indigo;

          &[disabled] {
            opacity: 0.35;
          }

          &#add-test-button {
            background: url("../assets/images/add.svg") center no-repeat;
          }

          &#duplicate-test-button {
            background: url("../assets/images/duplicate.svg") center no-repeat;
          }

          &#delete-test-button {
            background: url("../assets/images/delete.svg") center no-repeat;
          }

          &#confirm-test-button {
            background: url("../assets/images/confirm.svg") center no-repeat;

            &.new {
              background: url("../assets/images/new.svg") center no-repeat;
            }
          }

          &#cancel-test-button {
            background: url("../assets/images/cancel.svg") center no-repeat;
          }
        }
      }
    }

    #test-runner-container {
      // Just contains the play button ATM, this will make sure the size doesn't shift when hidden
      width: 35px;
      height: 35px;
      margin: 0 1rem 0 0;

      button#run-test {
        width: 100%;
        height: 100%;
        padding: 0.5rem 0.8rem;
        border-radius: $border-radius-medium;
        background: $orange;
        border: none;
        cursor: pointer;
      }

      button#run-test.success {
        outline: solid 2px green;
      }

      button#run-test.failure {
        outline: solid 2px red;
      }

      #arrow {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: solid 6px transparent;
        border-bottom: solid 6px transparent;
      }

      #arrow.test-button-running {
        border-left: solid 10px grey;
      }

      #arrow.test-button-ready {
        border-left: solid 10px white;
      }
    }
  }
}
</style>
