import { Node } from "@baklavajs/core";

export class Assert extends Node {
  constructor() {
    super();
    this.type = "Assert";
    this.name = "Assert";

    this.customClasses = "collapse-interface";

    /*
        This will ALWAYS exist as an option on every mockable node regardless
        of whether we're in test mode or not (but it will be hidden)
    */
    this.addOption("TestFixture", "TestFixtureInput");

    this.addInputInterface("Input");
  }
}
