import { Node } from "@baklavajs/core";

export class DateOperation extends Node {
  constructor() {
    super();

    this.type = "DateOperation";
    this.name = "Date Operation";

    this.customClasses = "date-operation date";

    this.addInputInterface("Input");
    this.addInputInterface("Value");

    this.operations = ["Set Time Zone"];
    this.addOption("Operation", "SelectOption", "Set Time Zone", undefined, {
      items: this.operations
    });

    this.addOutputInterface("Output");
  }
}
