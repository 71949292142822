import { Node } from "@baklavajs/core";

export class SetValue extends Node {
  constructor() {
    super();
    this.type = "SetValue";
    this.name = "Set Value";
    this.twoColumn = true;

    this.addInputInterface("Flow");
    this.addInputInterface("Value");
    this.addOutputInterface("Output");
  }
}
