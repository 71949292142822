<template>
  <div>
    <input
      class="dark-input"
      type="text"
      placeholder="key"
      v-model="inputValue"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["name", "value", "node"],
  data: function() {
    return {
      inputValue: ""
    };
  },
  mounted() {
    this.inputValue = this.node.getOptionValue(this.name);
  
  },
  updated() {
    this.node.setOptionValue(this.name, this.inputValue);
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>
