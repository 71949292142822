<template>
  <div class="add-input">
    <button @click="add">Add</button>
    <button @click="remove">Remove</button>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["name", "value", "node", "option"],
  data: function() {
    return {
      inputs: []
    };
  },
  mounted() {
    for (const key in this.node.inputInterfaces) {
      this.inputs.push(key);
    }
  },
  updated() {
    this.node.setOptionValue(this.name, this.inputs);
  },
  methods: {
    add: function() {
      // Generate the input label
      let inputName = "Input " + new Date().getTime();

      // If the user provides a custom function to generate the input label, use that instead
      if (this.option.inputLabelFunction !== undefined) {
        inputName = this.option.inputLabelFunction();
      }

      this.node.addInputInterface(inputName);
      this.inputs.push(inputName);
    },
    remove: function() {
      const inputIndex = this.inputs.length - 1;

      if (inputIndex > 0) {
        this.node.removeInterface(this.inputs[inputIndex]);
        this.inputs.splice(inputIndex, 1);
      }
    }
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";

.add-input {
  button {
    padding: 0.5rem;
    margin: 0 0.5rem 0 0;
    color: #fff;
    background: $off-black;
    border: solid 1px $grey;
    border-radius: $border-radius-small;
    cursor: pointer;

    &:hover {
      color: $orange;
      border: solid 1px $orange;
    }
  }
}
</style>
