<template>
  <portal to="modal-portal">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" @click="$emit(`close`)" />

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="header"></slot>
        </div>

        <div class="modal__body">
          <slot name="body"></slot>
        </div>

        <div class="modal__footer">
          <div>
            <button @click="$emit(`close`)">Cancel</button>
            <slot class="button-right" name="footer" />
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    openModal() {
      this.show = true;
    }
  }
};
</script>
