<template>
  <div class="regex">
    <span class="bound">/</span>
    <input
      type="text"
      class="dark-input pattern"
      placeholder="pattern"
      v-model="pattern"
    />
    <span class="bound">/</span>
    <input
      type="text"
      class="dark-input modifiers"
      placeholder="modifiers"
      v-model="modifiers"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["name", "node"],
  data() {
    return {
      pattern: "",
      modifiers: ""
    };
  },
  updated() {
    this.node.setOptionValue(this.name, {
      pattern: this.pattern,
      modifiers: this.modifiers
    });
  },
  mounted() {
    if (this.node.getOptionValue(this.name) !== null) {
      const optionValue = this.node.getOptionValue(this.name);
      this.pattern = optionValue.pattern;
      this.modifiers = optionValue.modifiers;
    }
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";

.regex {
  display: flex;
  align-items: center;
}

.bound {
  color: $orange;
}

.dark-input.pattern {
  width: 10rem;
  margin: 0 0.5rem;
}

.dark-input.modifiers {
  width: 5rem;
  margin-left: 0.5rem;
}
</style>
