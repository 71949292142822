import { Node } from "@baklavajs/core";

export class HTTP extends Node {
  constructor() {
    super();
    this.type = "HTTP";
    this.name = "HTTP";
    this.twoColumn = true;

    this.addOption("Method", "SelectOption", "POST", "", {
      items: ["POST", "PUT", "DELETE", "GET"]
    });

    /*
        This will ALWAYS exist as an option on every mockable node regardless
        of whether we're in test mode or not (but it will be hidden)
    */
    this.addOption("TestFixture", "TestFixtureInput");

    this.addInputInterface("Base URL");
    this.addInputInterface("Proxy URL");
    this.addInputInterface("Headers");
    this.addInputInterface("Payload");
    this.addInputInterface("Path");

    this.addOutputInterface("Response", { type: "response" });
  }
}
