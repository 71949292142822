<template>
  <div class="json-builder">
    <div class="json-builder-options" v-for="(input, index) in inputs" :key="index">
      <input type="text" placeholder="name" v-model="input.key" />
      <button class="json-builder-remove" @click="remove(index, input.name)" title="Remove item">
        ✖
      </button>
    </div>

    <button class="json-builder-add" @click="add">Add</button>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["name", "value", "node"],
  data: function() {
    return {
      inputs: []
    };
  },
  mounted() {
    this.inputs = [...this.value];
  },
  updated() {
    this.node.setOptionValue(this.name, this.inputs);
  },
  methods: {
    add: function() {
      const inputName = "Input " + new Date().getTime();

      this.inputs.push({ name: inputName, key: "" });

      this.node.addInputInterface(inputName);
    },
    remove: function(index, inputName) {
      this.inputs.splice(index, 1);
      this.node.removeInterface(inputName);
    }
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";

.json-builder {
  button {
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.4rem;
    color: #fff;
    background: $off-black;
    border: solid 1px $grey;
    border-radius: $border-radius-small;
    cursor: pointer;

    &:hover {
      color: $orange;
      border: solid 1px $orange;
    }

    &.json-builder-remove {
      width: 32px;
      height: 32px;
      margin-right: 0;
    }
  }

  .json-builder-options {
    display: flex;
  }

  input {
    margin: 0 0.5rem 0.6rem 0;
    padding: 0.5rem;

    color: #fff;
    background: $grey;
    border: none;
    border-radius: $border-radius-small;
  }
}
</style>
