import { Node } from "@baklavajs/core";

export class LoopContinue extends Node {
  constructor() {
    super();

    this.type = "LoopContinue";
    this.name = "Loop Continue";

    this.customClasses = "control-flow";

    this.addInputInterface("Input");
  }
}
