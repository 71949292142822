import { Node } from "@baklavajs/core";

export class MenuOutdated extends Node {
  constructor() {
    super();

    this.type = "MenuOutdated";
    this.name = "Menu Outdated";

    this.customClasses = "event collapse-interface";

    /*
        This will ALWAYS exist as an option on every mockable node regardless
        of whether we're in test mode or not (but it will be hidden)
    */
    this.addOption("TestFixture", "TestFixtureInput");

    this.addOutputInterface("MenuOutdated");
  }
}
