import { Node } from "@baklavajs/core";

export class VariableGet extends Node {
  constructor() {
    super();

    this.type = "VariableGet";
    this.name = "Variable Get";

    this.customClasses = "collapse-interface";

    this.addInputInterface("Flow");

    this.addOption("Name", "InputOption");

    this.addOutputInterface("Value");
  }
}
