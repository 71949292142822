<template>
  <div class="slider-toggle" :style="{ width: minWidth + 'px' }">
    <button
      v-for="(option, index) in options"
      v-bind:key="option"
      ref="slider-toggle-button"
      v-bind:class="{ selected: index === selectedOptionIndex }"
      @click="select(index)"
    >
      {{ option }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SliderToggle",
  props: {
    options: Array
  },
  data() {
    return {
      selectedOptionIndex: 0,
      minWidth: 0
    };
  },
  mounted() {
    let widestWidth = 0;

    // Get the width of the widest button
    this.$refs["slider-toggle-button"].forEach(button => {
      if (button.offsetWidth > widestWidth) {
        widestWidth = button.offsetWidth;
      }
    });

    // Increase the widest width so that even the largest button is aesthetically pleasing
    widestWidth += 32;

    // Round the width to the nearest 10
    const widestWidthRounded = Math.ceil(widestWidth / 10) * 10;

    // Set the size of the component to be large enough for equally sized buttons
    this.minWidth = widestWidthRounded * this.options.length;
  },
  methods: {
    select(index) {
      this.selectedOptionIndex = index;
      this.$emit("selected", this.selectedOptionIndex);
    }
  }
};
</script>

<style lang="scss">
@import "../styles/main.scss";

.slider-toggle {
  display: flex;
  align-self: center;
  align-items: center;
  height: 2rem;
  margin: 0 2rem 0 0;
  border-radius: 100px;
  border: solid 2px $color-mozzarella-70;
  background: $color-mozzarella-70;

  button {
    flex: 1;
    height: 100%;
    color: #ffffff;
    white-space: nowrap;
    box-sizing: border-box;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 100px;

    &.selected {
      font-weight: bold;
      color: #242e30;
      background: #ffffff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08),
        0 2px 12px -2px rgba(0, 0, 0, 0.32), 0 3px 6px rgba(0, 0, 0, 0.24);
      animation-name: pressed;
      animation-duration: 500ms;
    }
  }

  @keyframes pressed {
    from {
      border-radius: 5px;
      background: transparent;
    }
    to {
      border-radius: 100px;
      background: #ffffff;
    }
  }
}
</style>
