<template>
  <div class="number-option">
    <input type="number" min="0" placeholder="0" v-model.number="value" />
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["name", "node"],
  data() {
    return {
      value: null
    };
  },

  updated() {
    this.node.setOptionValue(this.name, this.value);
  },
  mounted() {
    this.value = this.node.getOptionValue(this.name)
      ? this.node.getOptionValue(this.name)
      : 0;
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";
.number-option {
  input {
    width: 100%;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.5rem;
    color: #fff;
    background: $grey;
    border: none;
    border-radius: $border-radius-small;
  }
}
</style>
