import { Node } from "@baklavajs/core";

export class ErrorMapping extends Node {
  constructor() {
    super();
    this.type = "ErrorMapping";
    this.name = "Error Mapping";

    this.addInputInterface("Input");
    this.addOption("ErrorMapping", "ErrorMappingOption");
    this.addOutputInterface("Error Code");
  }

  load(state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name.startsWith("Input")) {
        const intf = this.addInputInterface(name);
        intf.id = intfState.id;
      }
    });

    super.load(state);
  }
}
