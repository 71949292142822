<template>
    <div>
        <h1>Sidebar Option</h1>
        <p>This is an advanced option</p>
        <p>{{ value.testtext }}</p>
        <input-option v-model="value.testtext"></input-option>
    </div>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
class RequestSidebar extends Vue {
  @Prop() value;
}

export default RequestSidebar
</script>

<style scoped>

</style>
