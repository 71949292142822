import { Node } from "@baklavajs/core";

export class Coalesce extends Node {
  constructor() {
    super();

    this.type = "Coalesce";
    this.name = "Coalesce";

    this.customClasses = "collapse-interface control-flow";

    this.addOption("AddInput", "AddInputOption");

    this.addOutputInterface("Output");
  }

  load(state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name.includes("Input")) {
        const intf = this.addInputInterface(name);
        intf.id = intfState.id;
      }
    });

    super.load(state);
  }
}
