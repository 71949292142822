<template>
  <div id="new">
    <header>
      <h2 ref="heading">{{ $route.params.project }} / {{ workflowName }}</h2>
    </header>

    <form>
      <div>
        <label for="name">Name</label
        ><input type="text" id="name" v-model="workflowName" />
      </div>
      <div>
        <label for="connector">Event</label>
        <select v-model="event">
          <option disabled value="">Please select an event</option>
          <option
            v-for="event in events"
            v-bind:key="event.identifier"
            :value="event.identifier"
          >
            {{ event.name }}
          </option>
        </select>
      </div>
      <button @click.prevent="createWorkflow()">Create Workflow</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "WorkflowsNew",
  data: () => {
    return {
      workflowName: "",
      event: "order-created",
      events: [
        {
          identifier: "order-created",
          name: "Order Created"
        },
        {
          identifier: "menu-outdated",
          name: "Menu Outdated"
        }
      ]
    };
  },
  methods: {
    createWorkflow() {
      let path = "/projects/" + this.$route.params.project + "/workflows";

      axios
        .post(path, {
          name: this.workflowName,
          hook: this.event
        })
        .catch(error => {
          console.error(error);
        })
        .then(() => {
          this.$router.push(path);
        });
    }
  }
};
</script>
