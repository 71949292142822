import { Node } from "@baklavajs/core";

export class MapValues extends Node {
  constructor() {
    super();
    this.type = "MapValues";
    this.name = "Map Values";
    this.twoColumn = true;

    this.addInputInterface("Map");
    this.addOutputInterface("Values");
  }
}
