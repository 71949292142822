import { Node } from "@baklavajs/core";

export class Configuration extends Node {
  constructor() {
    super();
    this.type = "Configuration";
    this.name = "Configuration";

    this.customClasses = "collapse-interface";

    this.addOption("Key", "QueryOption");

    /*
        This will ALWAYS exist as an option on every mockable node regardless
        of whether we're in test mode or not (but it will be hidden)
    */
    this.addOption("TestFixture", "TestFixtureInput");

    this.addOutputInterface("Output");
    this.addOutputInterface("Not Found");
  }
}
