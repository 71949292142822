import { Node } from "@baklavajs/core";

export class JsonBuilder extends Node {
  constructor() {
    super();
    this.type = "JsonBuilder";
    this.name = "JSON Builder";
    this.twoColumn = false;
    this.customClasses = "collapse-interface";

    this.addOption("JsonBuilder", "JsonBuilderOption");

    this.addOutputInterface("Output");
  }

  load(state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name.includes("Input")) {
        const intf = this.addInputInterface(name);
        intf.id = intfState.id;
      }
    });

    super.load(state);
  }
}
