import { Node } from "@baklavajs/core";

export class EmitBaselinesDiscovered extends Node {
  constructor() {
    super();

    this.type = "EmitBaselinesDiscovered";
    this.name = "Emit Baselines Discovered";

    this.addInputInterface("URLs");
    this.addInputInterface("Restaurant Key");
  }
}
