import { Node } from "@baklavajs/core";

export class EmitOrderFailed extends Node {
  constructor() {
    super();
    this.type = "EmitOrderFailed";
    this.name = "Emit Order Failed";

    this.addInputInterface("Order ID");
    this.addInputInterface("Error Message");
    this.addInputInterface("Error Code");
  }
}
