import { Node } from "@baklavajs/core";

export class LoopEnd extends Node {
  constructor() {
    super();

    this.type = "LoopEnd";
    this.name = "Loop End";

    this.customClasses = "align-interface control-flow";

    this.addInputInterface("Each");
    this.addOutputInterface("Output");
  }
}
