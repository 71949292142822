import { Node } from "@baklavajs/core";

export class Text extends Node {
  constructor() {
    super();
    this.type = "Text";
    this.name = "Text";

    this.customClasses = "collapse-interface text";

    this.addOption("Text", "InputOption", "");

    this.addOutputInterface("Output");
  }
}
