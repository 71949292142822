import { Node } from "@baklavajs/core";

export class TextLiteral extends Node {
  constructor() {
    super();
    this.type = "TextLiteral";
    this.name = "Text Literal";

    this.customClasses = "collapse-interface text";

    this.addInputInterface("Input");
    this.addOutputInterface("Output");
  }
}
