import { Node } from "@baklavajs/core";

export class Regex extends Node {
  constructor() {
    super();
    this.type = "Regex";
    this.name = "Regex";
    this.twoColumn = true;
    this.addInputInterface("Input");
    this.addOption("Regex", "RegexOption");
    this.addOutputInterface("True");
    this.addOutputInterface("False");
  }
}
