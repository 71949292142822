import Vue from "vue";
import VueRouter from "vue-router";

import App from "@/App.vue";
import VueClipboard from "vue-clipboard2";
import store from "@/store";
import router from "@/router";

import { initAuth0, configureAxios } from "@flytio/shared-library";

import { BaklavaVuePlugin } from "@baklavajs/plugin-renderer-vue";
import "@baklavajs/plugin-renderer-vue/dist/styles.css";
import { token } from "@flytio/shared-library/routing/auth";
import moment from "moment";
import PortalVue from "portal-vue";

Vue.use(BaklavaVuePlugin);
Vue.use(VueClipboard);
Vue.use(PortalVue);

async function init() {
  Vue.config.productionTip = false;

  await initAuth0(window.location.origin);

  configureAxios(process.env.VUE_APP_BACKEND_URI, token);

  Vue.use(VueRouter);

  Vue.filter("formatDate", function(value) {
    if (value) {
      return moment(String(value)).format("YYYY-MM-DD HH:mm");
    }
  });

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
}

init();
