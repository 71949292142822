import { Node } from "@baklavajs/core";

export class NumberConditional extends Node {
  constructor() {
    super();
    this.type = "NumberConditional";
    this.name = "Number Conditional";
    this.twoColumn = true;
    this.customClasses = "number-conditional number";

    this.addInputInterface("Input");

    this.addOption("NumberConditional", "NumberConditionalOption");

    this.addOutputInterface("True");
    this.addOutputInterface("False");
  }

  load(state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name.startsWith("Input")) {
        const intf = this.addInputInterface(name);
        intf.id = intfState.id;
      }
    });

    super.load(state);
  }
}
