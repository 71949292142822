import { Node } from "@baklavajs/core";

export class TextJoin extends Node {
  constructor() {
    super();

    this.type = "TextJoin";
    this.name = "Text Join";

    this.customClasses = "text";

    this.addOption("AddInput", "AddInputOption", "", undefined, {
      inputLabelFunction: () => {
        return "Text " + (Object.keys(this.inputInterfaces).length + 1);
      }
    });

    this.addOutputInterface("Combined Text");
  }

  load(state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name.startsWith("Text")) {
        const intf = this.addInputInterface(name);
        intf.id = intfState.id;
      }
    });

    super.load(state);
  }
}
