<template>
  <div id="new">
    <header>
      <h2 ref="heading">Projects / {{ projectName }}</h2>
    </header>

    <form>
      <div>
        <label for="name">Name</label
        ><input type="text" id="name" v-model="projectName" />
      </div>
      <div>
        <label for="connector">Connector</label>
        <select v-model="connector">
          <option disabled value="">Please select a connector</option>
          <option
            v-for="conn in connectors"
            v-bind:key="conn.id"
            :value="conn.id"
            >{{ conn.name }}</option
          >
        </select>
      </div>
      <button @click.prevent="createProject">Create Project</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProjectsNew",
  data: () => {
    return {
      projectName: "",
      connector: "",
      connectors: []
    };
  },
  mounted() {
    this.loadConnectors();
  },
  methods: {
    loadConnectors() {
      axios.get("/connectors").then(response => {
        this.connectors = response.data;
      });
    },
    createProject() {
      let path = "/projects";

      axios
        .post(path, {
          name: this.projectName,
          connector: this.connector
        })
        .catch(error => {
          console.error(error);
        })
        .then(response => {
          this.$router.push(path + "/" + response.data.id + "/workflows/new");
        });
    }
  }
};
</script>
