<template>
  <div>

    <modal @close="closeModal" ref="validationErrorModal">
      <template v-slot:header>
        validation failed
      </template>
      <template v-slot:body>
        Please resolve the following issues -
        <ul>
          <li v-for="item in validationErrors" :key="item.name">{{ item }}</li>
        </ul>
      </template>
      <template v-slot:footer> </template>
    </modal>

    <a href="#" @click.prevent="save" title="Save workflow changes.">
      <span v-bind:class="{ saving: state === saveState.Saving }"></span>
      <span v-if="state === saveState.Default">Save</span>
      <span v-if="state === saveState.Saving">Saving</span>
      <span v-if="state === saveState.Saved">Saved</span>
      <span v-if="state === saveState.Failed">Failed</span>
    </a>
  </div>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import Modal from "./Modal.vue";

const saveState = {
  Default: "Save",
  Saving: "Saving",
  Saved: "Saved",
  Failed: "Failed"
};

export default {
  name: "SaveButton",
  data: function() {
    return {
      state: saveState.Default,
      saveState: saveState,
      validationErrors: []
    };
  },
  methods: {
    openModal() {
      this.$refs.validationErrorModal.openModal();
    },
    closeModal() {
      this.$refs.validationErrorModal.closeModal();
    },
    sleep: function(seconds) {
      return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    },
    validateGraph(workflow) {
      // Make the event hook match the node name convention
      const workflowHook = this.$store.state.workflow.data.hook
        .replace("-", "")
        .toLowerCase();

      // Validate all nodes
      const validationResult = workflow.nodes.reduce(
        (validationResult, node) => {
          // There should always be an event node which matches the graph type
          if (node.type.toLowerCase() === workflowHook) {
            validationResult.hasEventHook = true;
          }

          // There should always be at least one HTTP node
          if (node.type === "HTTP") {
            validationResult.hasHTTPNode = true;
          }

          // The graph should have matching loop to loop end pairs
          node.type === "Loop" && validationResult.loops.loopStartCount++;
          node.type === "LoopEnd" && validationResult.loops.loopEndCount++;

          // There should be no empty option for the pick and configuration node
          const hasMissingOptions = node =>
            node.options.some(
              ([key, value]) =>
                key !== "TestFixture" && (value === null || value === "")
            );

          if (node.type === "Pick") {
            hasMissingOptions(node) &&
              validationResult.pickNodesWithEmptyOptions.push(node);
          }
          if (node.type === "Configuration") {
            hasMissingOptions(node) &&
              validationResult.configurationNodesWithEmptyOptions.push(node);
          }

          return validationResult;
        },
        {
          hasEventHook: false,
          hasHTTPNode: false,
          pickNodesWithEmptyOptions: [],
          configurationNodesWithEmptyOptions: [],
          loops: {
            loopStartCount: 0,
            loopEndCount: 0
          }
        }
      );

      return this.createUserFriendlyValidationErrorMessages(
        validationResult,
        workflowHook
      );
    },
    createUserFriendlyValidationErrorMessages(validationResult, workflowHook) {
      let validationErrors = [];

      if (!validationResult.hasEventHook) {
        validationErrors.push(
          `Workflow must have an event node which matches the workflows event hook (${workflowHook}).`
        );
      }

      if (!validationResult.hasHTTPNode) {
        validationErrors.push("Workflow must contain an HTTP node.");
      }

      if (
        validationResult.loops.loopEndCount !==
        validationResult.loops.loopStartCount
      ) {
        validationErrors.push(
          `Each Loop node should have a matching Loop End node to form a pair. Workflow contains ${validationResult.loops.loopStartCount} Loop starts, and ${validationResult.loops.loopEndCount} loop ends.`
        );
      }

      const messageBuilder = (nodeName, nodeCount) => {
        return `Workflow contains ${nodeCount} "${nodeName}" node${
          nodeCount > 1 ? `'s` : ""
        } with missing values in one or more fields.`;
      };

      if (validationResult.configurationNodesWithEmptyOptions.length) {
        validationErrors.push(
          messageBuilder(
            "Configuration",
            validationResult.configurationNodesWithEmptyOptions.length
          )
        );
      }

      if (validationResult.pickNodesWithEmptyOptions.length) {
        validationErrors.push(
          messageBuilder(
            "Pick",
            validationResult.pickNodesWithEmptyOptions.length
          )
        );
      }

      return validationErrors;
    },
    save: function() {
      // To stop people from re-saving while a save is happening
      if (this.state === saveState.Saving) {
        return;
      }

      this.state = saveState.Saving;

      const workflow = this.$store.state.editor.save();

      const validationResult = this.validateGraph(workflow);
      if (validationResult.length) {
        // Display a modal, with the validation errors
        this.validationErrors = validationResult;
        this.state = saveState.Failed;
        this.openModal();

        return;
      }

      let graphSaveRequest = axios
        .put(this.$route.path, {
          graph: (() => {
            /*
              The requirement here was to render a "text area" for any node which requires test fixture data. Originally,
              we had hoped to extend the base "Node" component to render these text areas depending on whether we're in
              test mode (and the custom node required it).

              We were unfortunately unable to find a way to "inject" this text area into the parent node, and rather we
              have to add it to each custom component individually as an "option".

              The current solution, is that When we're in test mode, certain custom node's will render a `TestFixtureInput`
              component as an "option" (E.G `configuration.js`) in order to collect test fixture data. We must remove
              from the baklava save structure, as the backend does not understand these options, and will throw an error.
              The data from these `TestFixtureInput` is sent in a separate request, below this.

              There is an open issue here discussing whether there may be a better way to achieve this goal
              https://github.com/newcat/baklavajs/issues/203
            */
            let nodesWithTestOptionsRemoved = workflow.nodes.map(node => {
              return {
                ...node,
                options: node.options.filter(options => {
                  return !options.includes("TestFixture");
                })
              };
            });

            return {
              ...workflow,
              nodes: nodesWithTestOptionsRemoved
            };
          })()
        })
        .then(async () => {
          this.$store.state.workflow.data.graph = cloneDeep(workflow);
        });

      const promises = [graphSaveRequest];

      if (this.$store.state.workflowTesting.selectedTest.id) {
        let testSaveRequest = axios
          .put(
            `${this.$route.path}/tests/${this.$store.state.workflowTesting.selectedTest.id}`,
            (() => {
              /*
                There is a chance a user will have added / removed nodes from the graph
                since we added them (which TestFixtureInput cannot detect), so we need to remove any nodes that aren't in the graph.
                Perhaps we should listen to some baklava hook, and do this filtering then?
              */
              this.$store.state.workflowTesting.selectedTest.nodes = this.$store.state.workflowTesting.selectedTest.nodes.filter(
                node => workflow.nodes.find(n => n.id === node.id) !== undefined
              );

              return { ...this.$store.state.workflowTesting.selectedTest };
            })()
          )
          .then(async res => {
            console.log(res);
          });

        promises.push(testSaveRequest);
      }

      return Promise.allSettled(promises).then(async values => {
        if (values.every(v => v.status === "fulfilled")) {
          this.state = saveState.Saved;
          this.$store.state.needsSaving = false;

          await this.sleep(1);
          this.state = saveState.Default;
        } else {
          // Always show the saving state for a perceivable amount of time
          await this.sleep(1.5);

          // Let the user know the save has failed
          this.state = saveState.Failed;
          await this.sleep(5);
          this.state = saveState.Default;

          // Log errors for troublesome requests
          values.forEach(v => {
            if (v.status === "rejected") {
              console.log(v.reason);
            }
          });
        }
      });
    }
  },
  components: {
    Modal
  }
};
</script>

<style scoped>
.saving:before {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 10px;
  -webkit-animation: spin 1.2s infinite linear;
  animation: spin 1.2s infinite linear;
  border: 2px white dotted;
  border-left-color: black;
  border-radius: 100%;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
