import { Node } from "@baklavajs/core";

export class Math extends Node {
  constructor() {
    super();

    this.type = "Math";
    this.name = "Math";

    this.twoColumn = true;
    this.customClasses = "number";

    this.operations = ["ADD", "SUBTRACT", "DIVIDE", "MULTIPLY", "MODULUS"];

    this.addInputInterface("Value 1");
    this.addInputInterface("Value 2");

    this.addOption("Operation", "SelectOption", this.operations[0], undefined, {
      items: this.operations
    });

    this.addOutputInterface("Result");
  }
}
