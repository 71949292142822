<template>
  <div class="numberConditional">
    <div v-for="(conditional, index) in conditionals" :key="index">
      <select v-model="conditional.andOr" v-if="index !== 0">
        <option value="&&">AND</option>
        <option value="||">OR</option>
      </select>
      <select v-model="conditional.operator">
        <option
          v-for="operator in operators"
          v-bind:key="operator.value"
          v-bind:value="operator.value"
          >{{ operator.name }}</option
        >
      </select>
      <button
        v-if="index != 0"
        class="conditional-remove"
        @click="remove(index, conditional.name)"
        title="Remove item"
      >
        ✖
      </button>
    </div>

    <button class="conditional-add" @click="add">Add</button>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["name", "node"],
  data() {
    return {
      conditionals: [],
      operators: [
        {
          name: "EQUAL",
          value: "=="
        },
        {
          name: "NOT EQUAL",
          value: "!="
        },
        {
          name: "GREATER THAN",
          value: ">"
        },
        {
          name: "LESS THAN",
          value: "<"
        }
      ]
    };
  },
  methods: {
    add: function() {
      const inputName = "Input " + new Date().getTime();
      this.node.addInputInterface(inputName);

      this.conditionals.push({
        andOr: "&&",
        operator: "==",
        value: "",
        name: inputName
      });
    },
    remove: function(index, inputName) {
      this.conditionals.splice(index, 1);
      this.node.removeInterface(inputName);
    }
  },
  updated() {
    this.node.setOptionValue(this.name, this.conditionals);
  },
  mounted() {
    if (this.node.getOptionValue(this.name) !== null) {
      this.conditionals = this.node.getOptionValue(this.name);
    }
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";
.node {
  max-width: 500px !important;
}
.numberConditional {
  button {
    padding: 0.5rem;
    margin: 0 0.5rem 0 0;
    color: #fff;
    background: $off-black;
    border: solid 1px $grey;
    border-radius: $border-radius-small;
    cursor: pointer;
    &:hover {
      color: $orange;
      border: solid 1px $orange;
    }
    &.conditional-remove {
      width: 35px;
      margin-right: 0;
    }
  }
  input,
  select {
    width: calc(45% - 0.5rem - 20px); // Naive size - margin - remove button
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.4rem;
    color: #fff;
    background: $grey;
    border: none;
    border-radius: $border-radius-small;
  }
}
</style>
