import { Node } from "@baklavajs/core";

export class Date extends Node {
  constructor() {
    super();

    this.type = "Date";
    this.name = "Date";

    this.customClasses = "date";

    this.addOption("Format", "SelectOption", "RFC3339 (UTC)", undefined, {
      items: ["Unix", "RFC3339", "RFC3339 (UTC)", "RFC3339Nano"]
    });

    this.addOutputInterface("Output");
  }
}
