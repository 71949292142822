<template>
  <div v-show="shouldShowTestFixtureButton" class="input-user-select">
    <!-- Depending on which type of node rendered this, either display the button within the node, or above -->
    <div v-if="this.$attrs.node.name === 'Assert'">
      <input
        class="test-assertion-input"
        type="button"
        @click="openModal()"
        value="Expectation"
      />
    </div>
    <div v-else>
      <input
        class="button test-fixture-input"
        type="button"
        @click="openModal()"
        value="EDIT TEST DATA"
      />
    </div>

    <modal @close="closeModal" ref="testFixtureModal">
      <template v-slot:body>
        <div class="error-container">
          <div v-if="!isValidJSON" class="error-content">
            <!-- Icon "x" from https://heroicons.com/ -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="cross"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <span>Invalid JSON detected, please review</span>
          </div>
        </div>
        <textarea id="fixture-text-area" v-model="nodeFixture" />
      </template>
      <template v-slot:footer>
        <button class="button-right" @click="updateFixture()">Update</button>
        <button class="button-right" @click="prettifyContent()">
          Prettify
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import Modal from "../components/Modal.vue";

export default Vue.extend({
  data: function() {
    return {
      isValidJSON: true,
      // On first load, we'll set this to the current value of the node in global store
      nodeFixtureValue: this.getSavedNodeFixtureValue()
    };
  },
  computed: {
    shouldShowTestFixtureButton: function() {
      return (
        // You shouldn't be able to see the "Edit fixture" button unless you
        // are in test mode AND actually have a test fixture create & selected
        this.$store.state.workflowTesting.isInTestMode &&
        this.$store.state.workflowTesting.selectedTest.id !== 0
      );
    },
    // Usage explained in "watch" section
    currentSavedFixtureValue() {
      return this.getSavedNodeFixtureValue();
    },
    nodeFixture: {
      get() {
        return this.nodeFixtureValue;
      },
      set(newFixtureValue) {
        // Potentially display warning above the textarea, then update local copy of the fixture value (will only be commited to global store when user clicks "Update")
        this.checkIsValidJSON(newFixtureValue);
        this.nodeFixtureValue = newFixtureValue;
      }
    }
  },
  methods: {
    openModal() {
      this.$refs.testFixtureModal.openModal();
    },
    closeModal() {
      // Reset local value of this nodes fixture, to the global value
      this.nodeFixtureValue = this.currentSavedFixtureValue;
      this.$refs.testFixtureModal.closeModal();
    },
    updateFixture() {
      this.$refs.testFixtureModal.closeModal();
      this.updateNodeFixtureData();
    },
    checkIsValidJSON(val) {
      try {
        JSON.stringify(JSON.parse(val), null, 2);
        this.isValidJSON = true;
      } catch (e) {
        this.isValidJSON = false;
      }
    },
    // val is optional. if not provided, it will use the current local value of the textarea
    prettifyContent(val = this.nodeFixtureValue) {
      let prettifierContent = val;
      try {
        prettifierContent = JSON.stringify(JSON.parse(val), null, 2);
        this.nodeFixtureValue = prettifierContent;
      } catch (e) {
        console.log("Tried to prettify invalid JSON");
      }
    },
    getSavedNodeFixtureValue: function() {
      let foundNode = this.$store.state.workflowTesting.selectedTest.nodes.find(
        node => node.id === this.$attrs.node.id
      );

      return foundNode?.fixture;
    },
    updateNodeFixtureData: function() {
      let updatedTest = {
        ...this.$store.state.workflowTesting.selectedTest,
        nodes:
          // Either find existing fixture data for node in global store, or create a new one and update
          this.getSavedNodeFixtureValue() !== undefined
            ? this.$store.state.workflowTesting.selectedTest.nodes.map(node =>
                // this.$attrs.node.id is the id of the node we're editing (which rendered this option)
                node.id === this.$attrs.node.id
                  ? { ...node, fixture: this.nodeFixtureValue }
                  : node
              )
            : [
                ...this.$store.state.workflowTesting.selectedTest.nodes,
                {
                  id: this.$attrs.node.id,
                  type: this.$attrs.node.type,
                  fixture: this.nodeFixtureValue
                }
              ]
      };
      // Update global store with the latest node fixture data, however this will not be commited to the DB until the user clicks "Save" on nav.
      this.$store.state.workflowTesting.selectedTest = updatedTest;
    }
  },
  watch: {
    // Watch when the GLOBAL value for the node's fixture changes (happens when we add a new test, or load)
    // then update the "default" local state of the fixture textarea.
    currentSavedFixtureValue() {
      this.nodeFixtureValue = this.getSavedNodeFixtureValue();
    }
  },
  components: {
    Modal
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";

.test-assertion-input {
  // Match style of other node "option" buttons, such as "JsonBuilderOption"
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.4rem;
  color: #fff;
  background: $off-black;
  border: solid 1px $grey;
  border-radius: $border-radius-small;
  cursor: pointer;

  &:hover {
    color: $orange;
    border: solid 1px $orange;
  }
}

.test-fixture-input {
  position: absolute;
  top: -50px;
  left: -2px;
}

.cross {
  width: 24px;
  height: 24px;
  color: red;
}

.error-container {
  min-height: 35px;
}

.error-content {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-family: "FanfareTicker", Helvetica, sans-serif !important;
}

#fixture-text-area {
  height: 300px;
}
</style>
