import { Node } from "@baklavajs/core";

export class List extends Node {
  constructor() {
    super();

    this.type = "List";
    this.name = "List";

    this.customClasses = "collapse-interface list";

    this.addOutputInterface("Output");
  }
}
