import { Node } from "@baklavajs/core";

export class Loop extends Node {
  constructor() {
    super();

    this.type = "Loop";
    this.name = "Loop";

    this.customClasses = "align-interface control-flow";

    this.addInputInterface("Collection");
    this.addOutputInterface("Each");
  }
}
