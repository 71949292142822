import { Node } from "@baklavajs/core";

export class DateTransform extends Node {
  constructor() {
    super();

    this.type = "DateTransform";
    this.name = "Date Transform";

    this.customClasses = "collapse-interface date";

    this.operations = ["Add Seconds", "Add Minutes"];
    this.dateFormat = ["Unix", "RFC3339", "RFC3339 (UTC)", "RFC3339Nano"];

    this.addInputInterface("Input");

    this.addOption("Format", "SelectOption", "RFC3339 (UTC)", undefined, {
      items: this.dateFormat
    });

    this.addOption("Operation", "SelectOption", "Add Minutes", undefined, {
      items: this.operations
    });

    this.addOption("Value", "InputOption", "");

    this.addOutputInterface("Output");
  }
}
