import { Node } from "@baklavajs/core";

export class Plu extends Node {
  constructor() {
    super();

    this.type = "Plu";
    this.name = "Plu";
    this.twoColumn = true;

    this.addInputInterface("RestaurantKey");
    this.addInputInterface("Reference");

    this.addOutputInterface("PLU");
    this.addOutputInterface("Error");
  }
}
