import { Node } from "@baklavajs/core";

export class MapPick extends Node {
  constructor() {
    super();
    this.type = "MapPick";
    this.name = "Map Pick";
    this.twoColumn = true;

    this.addInputInterface("Map");
    this.addInputInterface("Key");

    this.addOutputInterface("Output");
    this.addOutputInterface("Not Found");
  }
}
