import { Node } from "@baklavajs/core";

export class MapSet extends Node {
  constructor() {
    super();
    this.type = "MapSet";
    this.name = "Map Set";
    this.twoColumn = true;

    this.addInputInterface("Map");
    this.addInputInterface("Key");
    this.addInputInterface("Value");
    this.addOutputInterface("Output");
  }
}
