<template>
  <div id="runway">
    <header id="main" v-if="loggedIn">
      <h1><a href="/">Runway</a></h1>

      <nav>
        <ul>
          <li><a href="/projects">Projects</a></li>
          <li><a href="#" @click="userLogout">Logout</a></li>
        </ul>
      </nav>
    </header>

    <router-view />

    <!-- Allows us to render all modals at top level, in order to overcome display issues when trying to render within the node-editor tree-->
    <portal-target name="modal-portal"> </portal-target>
  </div>
</template>

<script>
import { logout } from "@flytio/shared-library";
import { configureAxios } from "@flytio/shared-library";
import {
  user,
  token,
  isAuthenticated
} from "@flytio/shared-library/routing/auth";

export default {
  data() {
    return {
      loggedIn: false
    };
  },
  async mounted() {
    if (user && isAuthenticated && token) {
      this.loggedIn = true;
      await configureAxios(
        process.env.VUE_APP_BACKEND_URI,
        token,
        process.env.VUE_APP_FLYT_API_KEY
      );
    } else {
      this.loggedIn = false;
    }
  },
  methods: {
    userLogout: function() {
      logout();
    }
  }
};
</script>

<style lang="scss">
@import "styles/main.scss";

header#main {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 80px;
  background: #1a1a19;

  h1 {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-family: "FanfareTicker", Helvetica, sans-serif !important;
    font-size: 1.6rem;
    letter-spacing: 0.3rem;

    a {
      display: block;
      height: 100%;
      color: #fff;
      text-decoration: none;
      vertical-align: middle;
      line-height: 50px;

      &:hover,
      &:active {
        color: $orange !important;
      }
    }
  }

  nav {
    background: $off-black;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 50px;

      li {
        display: inline-block;
        margin: 0 40px 0 0;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: inline-block;
          font-family: Helvetica, arial, sans-serif !important;
          color: #fff !important;
          text-align: center;
          vertical-align: middle;
          text-decoration: none;

          &:hover,
          &:active {
            color: $orange !important;
            border-color: $orange;
          }
        }
      }
    }
  }
}
</style>
