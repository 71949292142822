import { Node } from "@baklavajs/core";

export class NumberOperation extends Node {
  constructor() {
    super();

    this.type = "NumberOperation";
    this.name = "Number Operation";

    this.customClasses = "collapse-interface number";

    this.operations = ["ROUND", "TRUNCATE"];

    this.addInputInterface("Input");

    this.addOption("Operation", "SelectOption", this.operations[0], undefined, {
      items: this.operations
    });

    this.addOption("Precision", "InputOption");

    this.addOutputInterface("Output");
  }
}
