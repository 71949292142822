import { Node } from "@baklavajs/core";

export class TextOperation extends Node {
  constructor() {
    super();

    this.type = "TextOperation";
    this.name = "Text Operation";

    this.customClasses = "collapse-interface text";

    this.operations = [
      "PREPEND",
      "APPEND",
      "TRIM",
      "SPLIT",
      "FIRST",
      "LAST",
      "REMOVE WHITESPACE"
    ];

    this.addInputInterface("Input");

    const operationOption = this.addOption(
      "Operation",
      "SelectOption",
      this.operations[0],
      undefined,
      { items: this.operations }
    );

    this.addOption("Text", "InputOption");

    this.addOutputInterface("Output");

    operationOption.events.setValue.addListener(this, () => {
      this.onSelectOperation();
    });
  }

  onSelectOperation() {
    this.removeOption("Text");
    this.removeOption("Number");

    switch (this.getOptionValue("Operation")) {
      case this.operations[0]:
      case this.operations[1]:
      case this.operations[3]:
        this.addOption("Text", "InputOption");
        break;
      case this.operations[4]:
      case this.operations[5]:
        this.addOption("Number", "InputOption");
        break;
    }
  }
}
