import { Node } from "@baklavajs/core";

export class StoreBaseline extends Node {
  constructor() {
    super();

    this.type = "StoreBaseline";
    this.name = "Store Baseline";

    this.twoColumn = true;

    /*
        This will ALWAYS exist as an option on every mockable node regardless
        of whether we're in test mode or not (but it will be hidden)
    */
    this.addOption("TestFixture", "TestFixtureInput");

    this.addInputInterface("Baseline");
    this.addInputInterface("Restaurant Key");

    this.addOutputInterface("URL");
    this.addOutputInterface("Error");
  }
}
