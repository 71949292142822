import { Node } from "@baklavajs/core";

export class DateFormat extends Node {
  constructor() {
    super();

    this.type = "DateFormat";
    this.name = "Date Format";

    this.customClasses = "collapse-interface date";

    this.dateCurrentFormat = [
      "Unix",
      "RFC3339",
      "RFC3339 (UTC)",
      "RFC3339Nano"
    ];

    this.dateNewFormat = ["Unix", "RFC3339", "RFC3339 (UTC)", "RFC3339Nano"];

    this.addInputInterface("Input");

    this.addOption(
      "Current Format",
      "SelectOption",
      "RFC3339 (UTC)",
      undefined,
      { items: this.dateCurrentFormat }
    );

    this.addOption("New Format", "SelectOption", "RFC3339 (UTC)", undefined, {
      items: this.dateNewFormat
    });

    this.addOutputInterface("Output");
  }
}
