import { Node } from "@baklavajs/core";

export class Pick extends Node {
  constructor() {
    super();

    this.type = "Pick";
    this.name = "Pick";

    this.customClasses = "collapse-interface";

    this.addInputInterface("Input");

    this.addOption("Key", "QueryOption", "");

    this.addOutputInterface("Output");
    this.addOutputInterface("Not Found");
  }
}
