import VueRouter from "vue-router";
import Home from "@/views/Home";
import Graph from "@/views/Graph";
import Login from "@/views/Login";

import { AuthGuard } from "@flytio/shared-library";
import Projects from "../views/Projects";
import ProjectsNew from "../views/ProjectsNew";
import WorkflowsNew from "../views/WorkflowsNew";
import Workflows from "../views/Workflows";

function generateAuthGuard(to, from, next) {
  return AuthGuard(to, from, next, "/", "login")
}

const routes = [
  {
    path: "/",
    name: "AppHome",
    component: Home,
    beforeEnter: generateAuthGuard,
    redirect: "/projects/"
  },
  {
    path: "/graph",
    name: "Graph",
    component: Graph,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/projects/",
    name: "Projects",
    component: Projects,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/projects/new",
    name: "ProjectsNew",
    component: ProjectsNew,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/projects/:project/workflows/new",
    name: "WorkflowsNew",
    component: WorkflowsNew,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/projects/:project/workflows",
    name: "Workflows",
    component: Workflows,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/projects/:project/workflows/:workflow",
    name: "ViewWorkflow",
    component: Graph,
    beforeEnter: generateAuthGuard
  },
  {
    path: "/login",
    name: "RunwayLogin",
    component: Login,
    props: {
      loginTitle: "Runway"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
export { routes };
