<template>
  <div class="key-value">
    <fieldset>
      <legend>{{ name + " (" + value.length + ")" }}</legend>

      <div class="key-value-pair" v-for="(keyValuePair, index) in keyValuePairs" :key="index">
        <input type="text" placeholder="key" v-model="keyValuePair.key" />
        <input type="text" placeholder="value" v-model="keyValuePair.value" />
        <button class="key-value-remove" @click="remove(index)" title="Remove item">✖</button>
      </div>

      <button class="key-value-add" @click="add">Add</button>
    </fieldset>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["name", "value", "node"],
  data: function() {
    return {
      keyValuePairs: []
    };
  },
  mounted() {
    this.keyValuePairs = [...this.value];
  },
  updated() {
    this.node.setOptionValue(this.name, this.keyValuePairs);
  },
  methods: {
    add: function() {
      this.keyValuePairs.push({ key: "", value: "" });
    },
    remove: function(index) {
      this.keyValuePairs.splice(index, 1);
    }
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";

.key-value-pair {
  animation: slide-up 100ms;
}

.key-value {
  fieldset {
    padding: 0.5rem;
    border: solid 1px $grey;
    border-radius: $border-radius-small;

    legend {
      padding: 0 0.5rem;
      font-size: 0.7rem;
      color: #ccc;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }
  }

  button {
    padding: 0.5rem;
    margin: 0 0.5rem 0 0;
    color: #fff;
    background: $off-black;
    border: solid 1px $grey;
    border-radius: $border-radius-small;
    cursor: pointer;

    &:hover {
      color: $orange;
      border: solid 1px $orange;
    }

    &.key-value-remove {
      width: 35px;
      margin-right: 0;
    }
  }

  input {
    width: calc(50% - 0.5rem - 20px); // Naive size - margin - remove button
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.5rem;

    color: #fff;
    background: $grey;
    border: none;
    border-radius: $border-radius-small;
  }
}
</style>
