import { Node } from "@baklavajs/core";

export class Fork extends Node {
  constructor() {
    super();

    this.type = "Fork";
    this.name = "Fork";

    this.customClasses = "control-flow";

    this.addInputInterface("Input");
    this.addInputInterface("True Condition");
    this.addInputInterface("False Condition");

    this.addOutputInterface("True");
    this.addOutputInterface("False");
  }
}
