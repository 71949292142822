import { Node } from "@baklavajs/core";

export class EmitOrderSucceeded extends Node {
  constructor() {
    super();
    this.type = "EmitOrderSucceeded";
    this.name = "Emit Order Succeeded";

    this.addInputInterface("Flow");
    this.addInputInterface("Order ID");
  }
}
