<template>
  <div id="login">
    <div class="auth-panel">
      <h1>
        <router-link :to="$route.path" title="Runway">Runway</router-link>
      </h1>
      <h2 ref="title">{{ titleGarbled }}</h2>

      <button
        v-if="isLoggedIn && !hasError()"
        @click="goHome"
        id="continue"
      >
        Continue
      </button>

      <button
        v-if="!isLoggedIn && !hasError() && !isMabLogin"
        @click="login('google-oauth2')"
        class="button-social button-google"
        id="btn"
      >
        Login with Google
      </button>

      <button
        v-if="!isLoggedIn && !hasError() && !isMabLogin"
        @click="login('windowslive')"
        class="button-social button-microsoft"
      >
        Login with Microsoft
      </button>

      <button
        v-if="!isLoggedIn && !hasError() && isMabLogin"
        @click="login('flyt-connect-saml')"
        class="button-social button-microsoft"
      >
        Login with Microsoft
      </button>

      <div v-if="hasError()">
        <div v-html="$route.query.error_description"></div>
        <span class="rule clear"><span>Or you can</span></span>
        <button class="button button--primary" @click="logout()">
          Login with a different account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isAuthenticated,
  login,
  logout
} from "@flytio/shared-library/routing/auth";

export default {
  name: "Login",
  data: () => {
    return {
      isLoggedIn: isAuthenticated,
      title: "Runway",
      titleGarbled: "?+_3*—"
    };
  },
  props: {
    loginTitle: {
      type: String,
      default: "Runway"
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: "AppHome" });
    },
    login(connection) {
      login({
        redirect_uri: window.location.origin,
        connection: connection
      });
    },
    logout() {
      // Allows the user to choose another login method or select a different account
      logout({
        returnTo: window.location.origin,
        federated: true
      });
    },
    hasError() {
      return (
        typeof this.$route.query.error !== "undefined" &&
        typeof this.$route.query.error_description !== "undefined"
      );
    },
    revealText(title, titleGarbled, current) {
      const end = title.length;
      const titleUngarbled =
        title.substr(0, current) + titleGarbled.substr(current, end);

      setTimeout(() => {
        this.$refs.title.innerHTML = titleUngarbled;

        if (current !== end) {
          current++;

          this.revealText(title, titleGarbled, current);
        }
      }, 50);
    }
  },
  computed: {
    isMabLogin() {
      return this.$route.name === "MabLogin";
    }
  },
  mounted() {
    setTimeout(() => {
      this.revealText(this.title, this.titleGarbled, 1);
    }, 200);
  }
};
</script>

<style lang="scss">
@import "../styles/main.scss";

#login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .auth-panel {
    padding: 3rem 6rem;

    h1 {
      height: 4rem;

      a {
        display: block;
        height: 100%;
        background: url("~@flytio/shared-library/assets/images/flyt_logo.svg")
          center no-repeat;
        background-size: auto 100%;
        text-indent: -9999px;
        overflow: hidden;
      }
    }

    h2 {
      margin: 0 -1.4rem $space-extra-large 0 !important;
      padding: 0;
      font-family: "FanfareTicker", helvetica, sans-serif;
      font-weight: normal;
      font-size: 2.5rem;
      line-height: 2.5rem;
      letter-spacing: 1.4rem;
      color: #fff;
      text-align: right;
    }

    // TODO: padding, margin, border-radius, etc... to be in main.scss file
    .button-social {
      display: block;
      min-width: 240px;
      margin: 0 auto $space auto;
      padding: 1rem 1rem;
      font-size: 1rem;
      text-align: left;
      color: #fff;
      text-indent: 2rem;
      border: solid 1px $grey;
      border-radius: $border-radius-medium;
      background-size: auto 1.2rem;
      background-position: $space center;
      background-repeat: no-repeat;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: $orange;
        border-color: $orange;
      }
    }

    .button-google {
      background-image: url("~@flytio/shared-library/assets/images/google_g_logo.svg");
    }

    .button-microsoft {
      background-image: url("~@flytio/shared-library/assets/images/microsoft_logo.svg");
    }
  }

  .rule {
    display: block;
    width: 45%;
    height: 1em;
    margin: 0 auto calc(#{$space-large} + #{$space}) auto;
    line-height: 2rem;
    border-bottom: solid 1px $border-color;

    span {
      display: inline-block;
      padding: 0 $space;
      border-radius: $corner-small;
      background: white;
    }
  }
}
</style>
