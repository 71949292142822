import { Node } from "@baklavajs/core";

export class ListOperation extends Node {
  constructor() {
    super();

    this.type = "ListOperation";
    this.name = "List Operation";

    this.twoColumn = true;
    this.customClasses = "list";

    this.operations = ["ADD"];

    this.addInputInterface("List");
    this.addInputInterface("Value");

    this.addOption("Operation", "SelectOption", this.operations[0], undefined, {
      items: this.operations
    });

    this.addOutputInterface("Output");
  }
}
