import { Node } from "@baklavajs/core";

export class Number extends Node {
  constructor() {
    super();

    this.type = "Number";
    this.name = "Number";

    this.customClasses = "collapse-interface number";

    this.addOption("Value", "NumberOption");

    this.addOutputInterface("Output");
  }
}
