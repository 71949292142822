<template>
  <div id="listing">
    <header>
      <h2 ref="heading">{{ headingGarbled }}</h2>

      <ul>
        <li><a href="/projects/new" class="button">+ New Project</a></li>
      </ul>
    </header>

    <table v-if="projects">
      <thead>
        <tr>
          <th></th>
          <th>Workflows</th>
          <th>Updated</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="project in projects" v-bind:key="project.id">
          <td>
            <a :href="'/projects/' + project.id + '/workflows'">
              {{ project.name }}
            </a>
          </td>
          <td>{{ project.workflows }}</td>
          <td>{{ project.updated | formatDate }}</td>
          <td>{{ project.created | formatDate }}</td>
        </tr>
      </tbody>
    </table>

    <div v-else>
      No projects to display
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Projects",
  data: () => {
    return {
      heading: "Projects",
      headingGarbled: "?+_3*—0A",
      projects: []
    };
  },
  methods: {
    revealText(title, titleGarbled, current) {
      const end = title.length;
      const titleUngarbled =
        title.substr(0, current) + titleGarbled.substr(current, end);

      setTimeout(() => {
        this.$refs.heading.innerHTML = titleUngarbled;

        if (current !== end) {
          current++;

          this.revealText(title, titleGarbled, current);
        }
      }, 20);
    },
    load() {
      axios
        .get("/projects")
        .catch(error => {
          console.error(error);
        })
        .then(response => {
          this.projects = response.data;
        });
    }
  },
  mounted() {
    setTimeout(() => {
      this.revealText(this.heading, this.headingGarbled, 1);
    }, 200);

    this.load();
  }
};
</script>
