import { Node } from "@baklavajs/core";

export class Log extends Node {
  constructor() {
    super();
    this.type = "Log";
    this.name = "Log";

    this.addInputInterface("Message");
  }
}
