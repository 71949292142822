import { Node } from "@baklavajs/core";

export class Exists extends Node {
  constructor() {
    super();

    this.type = "Exists";
    this.name = "Exists";

    this.addInputInterface("Input");

    this.addOutputInterface("True");
    this.addOutputInterface("False");
  }
}
