<template>
  <div class="errorMapping">
    <select v-model="error.categoryType">
      <option disabled value="">Select category</option>
      <option
        v-for="category in categories"
        v-bind:key="category"
        v-bind:value="category"
        >{{ category }}</option
      >
    </select>
    <hr />
    <div v-if="error.categoryType">
      <div v-for="(map, index) in error.mappings" :key="index">
        <input type="text" v-model="map.text" placeholder="Error Text" />
        <select v-model="map.type">
          <option disabled value="">Select type</option>
          <option
            v-show="error.categoryType == type.category"
            v-for="type in types"
            v-bind:key="type.value"
            v-bind:value="type.value"
            >{{ type.name }}</option
          >
        </select>
        <button class="error-remove" @click="remove(index)" title="Remove item">
          ✖
        </button>
      </div>

      <button class="error-add" @click="add()">Add</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: ["name", "node"],
  data() {
    return {
      error: {
        categoryType: "",
        mappings: []
      },
      categories: ["Order", "Menu"],
      types: [
        {
          name: "Unknown",
          value: "UNKNOWN",
          category: "Order"
        },
        {
          name: "Inactive",
          value: "INACTIVE",
          category: "Order"
        },
        {
          name: "Incorrect Setup",
          value: "INCORRECT_SETUP",
          category: "Order"
        },
        {
          name: "In Use",
          value: "IN_USE",
          category: "Order"
        },
        {
          name: "Not Sure",
          value: "NOT_SURE",
          category: "Order"
        },
        {
          name: "Not Supported",
          value: "NOT_SUPPORTED",
          category: "Order"
        },
        {
          name: "Menu Error",
          value: "MENU_ERROR",
          category: "Order"
        },
        {
          name: "Malformed Request",
          value: "MALFORMED_REQUEST",
          category: "Order"
        },
        {
          name: "Auth Failed",
          value: "AUTH_FAILED",
          category: "Order"
        },
        {
          name: "Store Closed",
          value: "STORE_CLOSED",
          category: "Order"
        },
        {
          name: "Tender Error",
          value: "TENDER_ERROR",
          category: "Order"
        },
        {
          name: "Connectivity Issue",
          value: "CONNECTIVITY_ISSUE",
          category: "Order"
        },
        {
          name: "Item Unavailable",
          value: "ITEM_UNAVAILABLE",
          category: "Order"
        },
        {
          name: "Menu Unavailable",
          value: "MENU_UNAVAILABLE",
          category: "Order"
        },
        {
          name: "Item Not Valid",
          value: "ITEM_NOT_VALID",
          category: "Order"
        },
        {
          name: "Timeout",
          value: "TIMEOUT",
          category: "Order"
        },
        {
          name: "Error Unknown",
          value: "ERROR_UNKNOWN",
          category: "Menu"
        },
        {
          name: "Connection Failed",
          value: "CONNECTION_FAILED",
          category: "Menu"
        },
        {
          name: "Invalid Format",
          value: "INVALID_FORMAT",
          category: "Menu"
        },
        {
          name: "Invalid Configuration",
          value: "INVALID_CONFIGURATION",
          category: "Menu"
        },
        {
          name: "Internal Error",
          value: "INTERNAL_ERROR",
          category: "Menu"
        }
      ]
    };
  },
  methods: {
    add: function() {
      this.error.mappings.push({
        type: "",
        text: "",
        category: ""
      });
    },
    remove: function(index) {
      this.error.mappings.splice(index, 1);
    }
  },
  updated() {
    this.node.setOptionValue(this.name, this.error);
  },
  mounted() {
    if (this.node.getOptionValue(this.name) !== null) {
      this.error = this.node.getOptionValue(this.name);
      return;
    }
    this.add();
  }
});
</script>

<style lang="scss">
@import "../styles/main.scss";
.node {
  max-width: 500px !important;
}
.errorMapping {
  button {
    padding: 0.5rem;
    margin: 0 !important;
    color: #fff;
    background: $off-black;
    border: solid 1px $grey;
    border-radius: $border-radius-small;
    cursor: pointer;
    &:hover {
      color: $orange;
      border: solid 1px $orange;
    }
    &.conditional-remove {
      width: 35px;
    }
  }
  input {
    width: 58%;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.4rem;
    color: #fff;
    background: $grey;
    border: none;
    border-radius: $border-radius-small;
  }
  select {
    width: 32%;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.4rem;
    color: #fff;
    background: $grey;
    border: none;
    border-radius: $border-radius-small;
  }
  hr {
    margin-bottom: 15px;
    border: 1px solid rgb(90, 90, 90);
  }
}
</style>
