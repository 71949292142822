import { Node } from "@baklavajs/core";

export class Map extends Node {
  constructor() {
    super();
    this.type = "Map";
    this.name = "Map";

    this.addOutputInterface("Map");
  }
}
