import Vue from "vue";
import Vuex from "vuex";
import { Editor } from "@baklavajs/core";

Vue.use(Vuex);

const initialState = {
  state: {
    editor: new Editor(),
    needsSaving: false,
    workflow: {
      /*
        Initial response from the server on graph load, contains info such as the initial baklava graph to render,
        and info such as the "hook" (I.E the event type that triggers the workflow).
      */
      data: null
    },
    workflowTesting: {
      isInTestMode: false,
      testList: [],
      selectedTest: {
        id: 0,
        name: "",
        nodes: []
      },
      runner: {
        isLoading: false,
        latestResult: null
      }
    },
    graph: {
      isLoading: false
    }
  },
  mutations: {},
  actions: {},
  modules: {}
};

export default new Vuex.Store(initialState);
export { initialState };
