import { Node } from "@baklavajs/core";

export class VariableSet extends Node {
  constructor() {
    super();

    this.type = "VariableSet";
    this.name = "Variable Set";

    this.customClasses = "collapse-interface";

    this.addOption("Name", "InputOption");
    this.addInputInterface("Value");
  }
}
