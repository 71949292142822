<template>
  <div id="listing">
    <header>
      <h2 ref="heading">{{ headingGarbled }}</h2>

      <ul>
        <li><a href="workflows/new" class="button">+ New Workflow</a></li>
      </ul>
    </header>

    <table v-if="workflows">
      <thead>
        <tr>
          <th></th>
          <th>Events</th>
          <th>Enabled</th>
          <th>Updated</th>
          <th>Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(workflow, index) in workflows" v-bind:key="workflow.id">
          <td>
            <a :href="'/projects/' + project + '/workflows/' + workflow.id">
              {{ workflow.name }}
            </a>
          </td>
          <td>{{ workflow.hook }}</td>
          <td>
            <div v-bind:class="workflow.enabled ? 'toggle on' : 'toggle'">
              <div
                class="switch"
                @click="showToggleWorkflowEnabledConfirmationModal(index)"
              ></div>
            </div>
          </td>
          <td>{{ workflow.updated | formatDate }}</td>
          <td>{{ workflow.created | formatDate }}</td>
          <td>
            <div>
              <i
                class="trash"
                @click="showArchiveWorkflowConfirmationModal(index)"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else>
      No workflows to display
    </div>

    <modal
      @close="hideToggleWorkflowEnabledConfirmationModal"
      ref="toggleWorkflowModal"
    >
      <template v-slot:header>
        <span>Warning!</span>
      </template>
      <template v-slot:body>
        {{
          `Are you sure you want to ${
            workflows.length && index && !workflows[index].enabled
              ? "enable"
              : "disable"
          } this
        workflow?`
        }}
      </template>
      <template v-slot:footer>
        <button class="button-right" @click="toggleWorkflowEnabledState()">
          Update
        </button>
      </template>
    </modal>

    <modal @close="hideArchiveWorkflowConfirmationModal" ref="archiveModal">
      <template v-slot:header>
        Warning!
      </template>
      <template v-slot:body>
        {{
          `Are you sure you want to ARCHIVE the ${workflows &&
            workflows[index].name} workflow?`
        }}
      </template>
      <template v-slot:footer>
        <button class="button-right" @click="archive()">Archive</button>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Modal from "../components/Modal.vue";
export default {
  name: "Workflows",
  components: {
    Modal
  },
  data: () => {
    return {
      project: "",
      heading: "",
      headingGarbled: "?+_3*—0Aksn129",
      workflow: "",
      workflows: [],
      index: null
    };
  },
  methods: {
    revealText(title, titleGarbled, current) {
      const end = title.length;
      const titleUngarbled =
        title.substr(0, current) + titleGarbled.substr(current, end);

      setTimeout(() => {
        this.$refs.heading.innerHTML = titleUngarbled;

        if (current !== end) {
          current++;

          this.revealText(title, titleGarbled, current);
        }
      }, 20);
    },
    loadWorkflows() {
      let path = "/projects/" + this.$route.params.project + "/workflows";

      axios
        .get(path)
        .catch(error => {
          console.error(error);
        })
        .then(response => {
          this.workflows = response.data;
        });
    },
    showToggleWorkflowEnabledConfirmationModal(index) {
      this.index = index;
      this.$refs.toggleWorkflowModal.openModal();
    },
    hideToggleWorkflowEnabledConfirmationModal() {
      this.$refs.toggleWorkflowModal.closeModal();
    },
    toggleWorkflowEnabledState() {
      this.$refs.toggleWorkflowModal.closeModal();

      let path =
        "/projects/" +
        this.$route.params.project +
        "/workflows/" +
        this.workflows[this.index].id;

      axios
        .put(path, {
          enabled: !this.workflows[this.index].enabled
        })
        .catch(error => {
          console.error(error);
        })
        .then(() => {
          this.loadWorkflows();
        });

      this.index = null;
    },
    showArchiveWorkflowConfirmationModal(index) {
      this.index = index;
      this.$refs.archiveModal.openModal();
    },
    hideArchiveWorkflowConfirmationModal() {
      this.$refs.archiveModal.closeModal();
    },
    archive() {
      this.$refs.archiveModal.closeModal();

      let path =
        "/projects/" +
        this.$route.params.project +
        "/workflows/" +
        this.workflows[this.index].id;

      axios
        .delete(path, {
          identifier: this.workflows[this.index].id
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.loadWorkflows();
        });

      this.index = null;
    }
  },
  mounted() {
    this.heading = this.$route.params.project + " / Workflows";
    this.project = this.$route.params.project;

    setTimeout(() => {
      this.revealText(this.heading, this.headingGarbled, 1);
    }, 200);

    this.loadWorkflows();
  }
};
</script>
