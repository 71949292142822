import { Node } from "@baklavajs/core";

export class TextConditional extends Node {
  constructor() {
    super();
    this.type = "TextConditional";
    this.name = "Text Conditional";
    this.twoColumn = true;
    this.customClasses = "text-conditional text";

    this.addInputInterface("Input");

    this.addOption("TextConditional", "TextConditionalOption");

    this.addOutputInterface("True");
    this.addOutputInterface("False");
  }

  load(state) {
    state.interfaces.forEach(([name, intfState]) => {
      if (name.startsWith("Input")) {
        const intf = this.addInputInterface(name);
        intf.id = intfState.id;
      }
    });

    super.load(state);
  }
}
