import { Node } from "@baklavajs/core";

export class OrderCreated extends Node {
  constructor() {
    super();

    this.type = "OrderCreated";
    this.name = "Order Created";

    this.customClasses = "event collapse-interface";

    /*
        This will ALWAYS exist as an option on every mockable node regardless
        of whether we're in test mode or not (but it will be hidden)
    */
    this.addOption("TestFixture", "TestFixtureInput");

    this.addOutputInterface("OrderCreated");
  }
}
